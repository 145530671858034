import { createApp } from 'vue';
import { accountService } from '@/services/accountService';
import { axiosInterceptor } from '@/helpers/axiosInterceptor';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/css/tailwind.css';

// Enable interceptors from http requests
axiosInterceptor();

const app = createApp(App);

app.use(store);
app.use(router);
app.mount('#app');

accountService.startAuthenticateTimer();
