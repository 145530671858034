<template>
  <div class="home">
    This is the home page
  </div>
</template>

<script>
export default {
  name: 'Home',
};
</script>
