import { createStore } from 'vuex';

/* eslint-disable */
const config_production = true;
const config_client_path = (typeof communication_config === 'object' ? communication_config.client_path : '');
const apiUrl = 'https://development.identity.onlinefundraising.dk'; //(typeof communication_config === 'object' ? communication_config.api_url : (config_production ? 'https://development.identity.onlinefundraising.dk' : 'https://development.identity.onlinefundraising.dk'));
const userLocalStorage = 'OFuser';

const loginConfig = {
  client_id: 'identity_dev_92cfa9863da3',
  login_page_uri: `${apiUrl}/login_page.html`,
  redirect_uri: (window.location.href.indexOf('development.identitymanager') !== -1) ? 'https://development.identitymanager.onlinefundraising.dk/authorise-login' : 'http://localhost:8080/authorise-login',
  authorization_endpoint: `${apiUrl}/oauth/authorize`,
  token_endpoint: `${apiUrl}/oauth/token`,
  requested_scopes: 'tester offline_access',
};

export default createStore({
  state: {
    backendPath: config_client_path,
    apiUrl,
    userLocalStorage,
    loginConfig,
  },
});
/* eslint-enable */
