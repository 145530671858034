import axios from 'axios';
import { accountService } from '@/services/accountService';
import store from '../store/index';

export function axiosInterceptor() {
  axios.interceptors.request.use((request) => {
    /* eslint-disable */
    request.withCredentials = true;
    // add auth header with jwt if account is logged in and request is to the api url
    const account = accountService.accountValue;
    const isLoggedIn = account?.access_token;
    // const isApiUrl = request.url.startsWith(this.$store.state.apiUrl);
    // const isApiUrl = request.url.startsWith('https://development.identity.onlinefundraising.dk');
    const isApiUrl = request.url.startsWith(store.apiUrl);

    if (isLoggedIn && isApiUrl) {
      /* eslint-disable */
      request.headers.common['Authorization'] = `Bearer ${account.access_token}`;
      request.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    }

    return request;
  });
}
