<template>
  <div class="cursor-pointer">Authorize</div>
  <div class="cursor-pointer" @click="logout">Logout</div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import qs from 'qs';
import { accountService } from '@/services/accountService';

export default {
  name: 'AuthoriseLogin',
  computed: {
    params() {
      // First, parse the query string
      const params = {};
      const postBody = this.$route.hash.substring(1);
      const regex = /([^&=]+)=([^&]*)/g;
      let m = null;

      // eslint-disable-next-line
      while (m = regex.exec(postBody)) {
        params[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
      }

      return params;
    },
    getCode() {
      if (this.params.code) return this.params.code;
      return '';
    },
    getState() {
      if (this.params.state) return this.params.state;
      return '';
    },
  },
  created() {
    this.authorize();
  },
  methods: {
    logout() {
      accountService.logout();
      this.$router.push('/login');
    },
    async authorize() {
      // // Verify state matches what we set at the beginning
      // if (localStorage.getItem('pkce_state') !== this.getState) {
      //   alert('Invalid state');
      // } else {
      //   // Exchange the authorization code for an access token
      //   this.sendPostRequest(config.token_endpoint, {
      //     grant_type: 'authorization_code',
      //     code: this.getCode,
      //     client_id: config.client_id,
      //     redirect_uri: config.redirect_uri,
      //     code_verifier: localStorage.getItem('pkce_code_verifier')
      //   }, function (request, resp) {

      //     // Initialize your application now that you have an access token.
      //     // Here we just display it in the browser.
      //     document.getElementById('access_token').innerText = resp.access_token;
      //     document.getElementById('start').classList = 'hidden';
      //     document.getElementById('token').classList = '';
      //     // Replace the history entry to remove the auth code from the browser address bar
      //     //window.history.replaceState({}, null, '/');

      //     console.log(resp);
      //     console.log(['refresh_token', resp.refresh_token]);

      //     refreshInterval = setInterval(function () {

      //       console.log('refreshing jwt');

      //       refreshToken(config.token_endpoint);


      //     }, 60000);

      //     var payload = parseJwt(resp.access_token);

      //     console.log(payload);

      //   }, function (request, error) {
      //     // This could be an error response from the OAuth server, or an error because the
      //     // request failed such as if the OAuth server doesn't allow CORS requests
      //     document.getElementById('error_details').innerText = error.error + '\n\n' + error.error_description;
      //     document.getElementById('error').classList = '';
      //   });

      const data = {
        grant_type: 'authorization_code',
        code: this.getCode,
        client_id: this.$store.state.loginConfig.client_id,
        redirect_uri: this.$store.state.loginConfig.redirect_uri,
        code_verifier: localStorage.getItem('pkce_codeVerifier'),
      };

      const options = {
        method: 'POST',
        url: `${this.$store.state.apiUrl}/oauth/token`,
        data: qs.stringify(data),
      };
      axios(options)
        .then((response) => {
          console.log('Login response data');
          console.log(response.data);
          accountService.apiAuthenticate(response.data);
        })
        .catch((er) => {
          console.log('no data sorry', er);
        });
    },
    // Make a POST request and parse the response as JSON
    sendPostRequest(url, params, success, error) {
      var request = new XMLHttpRequest();
      request.open('POST', url, true);
      request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
      request.onload = function () {
        var body = {};
        try {
          body = JSON.parse(request.response);
        } catch (e) {
        }

        if (request.status == 200) {
          success(request, body);
        } else {
          error(request, body);
        }
      }
      request.onerror = function () {
        error(request, {});
      }
      var body = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      request.send(body);
    },
  },
};
</script>
