/* eslint-disable no-use-before-define */
import axios from 'axios';
import qs from 'qs';
import { BehaviorSubject } from 'rxjs';
import store from '../store/index';

const accountSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('OFaccount')));

export const accountService = {
  startAuthenticateTimer,
  apiAuthenticate,
  logout,
  account: accountSubject.asObservable(),
  get accountValue() { return accountSubject.value; },
};

// helper methods
let authenticateTimeout;

function startAuthenticateTimer() {
  if (accountSubject.value && accountSubject.value.access_token) {
    // parse json object from base64 encoded jwt token
    const jwtToken = parseJwt(accountSubject.value.access_token);

    // set a timeout to re-authenticate with the api one minute before the token expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    console.log(`Timer: ${timeout}`);
    authenticateTimeout = setTimeout(
      () => refreshToken(), 60 * 1000,
    );
  }
}

function stopAuthenticateTimer() {
  // cancel timer for re-authenticating with the api
  clearTimeout(authenticateTimeout);
}

async function apiAuthenticate(responseData) {
  localStorage.setItem(store.state.userLocalStorage, JSON.stringify(responseData));
  accountSubject.next(responseData);
  startAuthenticateTimer();
  return responseData;
}

async function refreshToken() {
  if (accountSubject.value && accountSubject.value.refresh_token) {
    const data = {
      grant_type: 'refresh_token',
    };
    const options = {
      method: 'POST',
      url: `${store.state.apiUrl}/oauth/token`,
      headers: { 'content-type': 'application/x-www-form-urlencoded; charset=UTF-8' },
      data: qs.stringify(data),
    };

    axios(options)
      .then((response) => {
        console.log('Refresh token response data');
        console.log(response.data);
        accountSubject.next(response.data);
        localStorage.setItem(store.state.userLocalStorage, JSON.stringify(response.data));
        console.log(accountSubject.value);
        startAuthenticateTimer();
      })
      .catch((er) => {
        console.log('no data sorry ', er);
      });
  }
}

function logout() {
  stopAuthenticateTimer();
  localStorage.removeItem(store.state.userLocalStorage);
  accountSubject.next(null);
}

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''),
  );

  return JSON.parse(jsonPayload);
}
