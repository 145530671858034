import { createRouter, createWebHistory } from 'vue-router';
import { Role } from '@/helpers/role';
import Home from '../views/Home.vue';
import Users from '../views/Users.vue';
import Login from '../views/Login.vue';
import AuthoriseLogin from '../views/AuthoriseLogin.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { accessRoles: [] },
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: { accessRoles: [Role.tester] },
  },
  {
    path: '/tester',
    name: 'Tester',
    component: Home,
    meta: { accessRoles: [Role.tester] },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/authorise-login',
    name: 'AuthoriseLogin',
    component: AuthoriseLogin,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const { accessRoles } = to.meta;
  const OFuser = localStorage.getItem('OFaccount');

  if (accessRoles) {
    if (!OFuser) {
      // not logged in so redirect to login page with the return url
      return next({ path: '/login', query: { returnUrl: to.path } });
    }

    // check if route is restricted by role
    if (accessRoles.length && !accessRoles.includes(OFuser.role)) {
      // role not authorised so redirect to home page
      return next({ path: '/' });
    }
  }

  return next();
});

export default router;
