<template>
  <div class="cursor-pointer" @click="redirect()">Generate login</div>
</template>

<script>
export default {
  name: 'Login',
  methods: {
    async redirect() {
      // Create and store a random 'state' value
      const state = this.generateRandomString();
      localStorage.setItem('pkce_state', state);
      // Create and store a new PKCE codeVerifier (the plaintext random secret)
      const codeVerifier = this.generateRandomString();
      localStorage.setItem('pkce_codeVerifier', codeVerifier);
      // Hash and base64-urlencode the secret to use as the challenge
      const codeChallenge = await this.pkceChallengeFromVerifier(codeVerifier);
      // Build the authorization URL
      const url = `${this.$store.state.loginConfig.authorization_endpoint}?response_type=code&client_id=${encodeURIComponent(this.$store.state.loginConfig.client_id)}&state=${encodeURIComponent(state)}&scope=${encodeURIComponent(this.$store.state.loginConfig.requested_scopes)}&login_page_uri=${encodeURIComponent(this.$store.state.loginConfig.login_page_uri)}&redirect_uri=${encodeURIComponent(this.$store.state.loginConfig.redirect_uri)}&code_challenge=${encodeURIComponent(codeChallenge)}&code_challenge_method=S256`;
      // Redirect to the authorization server
      window.location = url;
    },
    // Generate a secure random string using the browser crypto functions
    generateRandomString() {
      const array = new Uint32Array(28);
      window.crypto.getRandomValues(array);
      return Array.from(array, (dec) => `0${dec.toString(16)}`.substr(-2)).join('');
    },
    // Calculate the SHA256 hash of the input text.
    // Returns a promise that resolves to an ArrayBuffer
    sha256(plain) {
      const encoder = new TextEncoder();
      const data = encoder.encode(plain);
      return window.crypto.subtle.digest('SHA-256', data);
    },
    // Base64-urlencodes the input string
    base64urlencode(str) {
      // Convert the ArrayBuffer to string using Uint8 array to conver to what btoa accepts.
      // btoa accepts chars only within ascii 0-255 and base64 encodes them.
      // Then convert the base64 encoded to base64url encoded
      //   (replace + with -, replace / with _, trim trailing =)
      return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
        .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    },
    // Return the base64-urlencoded sha256 hash for the PKCE challenge
    async pkceChallengeFromVerifier(v) {
      const hashed = await this.sha256(v);
      return this.base64urlencode(hashed);
    },
  },
};
</script>
