<template>
  <div class="users">
    This is the users page
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Users',
  methods: {
    async GetUsers() {
      console.log('Get Users');

      const options = {
        method: 'GET',
        url: `${this.$store.state.apiUrl}/users`,
      };
      axios(options)
        .then((response) => {
          console.log(response.data);
        })
        .catch((er) => {
          console.log('no data sorry', er);
        });
    },
  },
};
</script>
